import { AppBar, Toolbar } from '@mui/material';

function MainBar(props) {
  return(
    <AppBar position="sticky" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
      </Toolbar>
    </AppBar>
  );
}

export default MainBar;
