import React from 'react';

import { Stack } from '@mui/material';
import { Link } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Chip } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Card, CardActions, CardActionArea, CardContent } from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Section(props) {
  return(<OrgContent
           key={props.node.ref}
           content={props.node.contents} />);
}

function Keyword(props) {
}

function Paragraph(props) {
  return(<div>
           <OrgContent
             key={props.node.ref}
             content={props.node.contents} />
         </div>
        );
}

function Bold(props) {
  return(<b><OrgContent
              key={props.node.ref}
              content={props.node.contents}
            />
         </b>);
}

function HeadlineDrawer(props) {
  const tags = props.node.properties.tags;
  const drawer = props.node.drawer;
  const ref = props.node.ref;

  // Special drawer rendering for specific tag types
  if(tags && drawer && (tags.includes('book') || tags.includes('movie'))) {
    return(
      <Grid container>
        {Object.entries(drawer).map(([key, value]) => {
          const hasJunk = value.endsWith('^{}');

          if(key !== 'ID' && value) {
            return(
              <React.Fragment>
                <Grid item xs={6}>{key}</Grid>
                <Grid item xs={6}>{hasJunk ? value.slice(0, -3) : value}</Grid>
              </React.Fragment>);
          }
          else {
            return null;
          }
        })}
      </Grid>
    );
  }
}

function HeadlineTags(props) {
  const tags = props.node.properties.tags;

  return(<Grid key={'tags'}
               container
               direction='row'
               justifyContent='flex-end' >
           {tags && tags.map((tag) => {
             return(<Grid key={tag}><Chip key={tag} label={tag} /></Grid>);
           })}
         </Grid>);
}

function Headline(props) {
  // console.log(props.node);
  return(<Accordion key={props.node.ref}>
           <AccordionSummary>
             <a id={props.node.drawer.ID}/>
             <Grid
               container
               justifyContent='space-between'
             >
               <Grid item sm={9} xs={9}>{props.node.properties.title[0]}</Grid>
               <Grid item sm={3} xs={3}><HeadlineTags node={props.node} /></Grid>
             </Grid>
           </AccordionSummary>
           <AccordionDetails>
             <HeadlineDrawer
               key={props.node.ref + 'drawer'}
               node={props.node} />
             <OrgContent
               key={props.node.ref}
               content={props.node.contents} />
           </AccordionDetails>
         </Accordion>
        );
}

function PlainList(props) {
  return(<List
           dense={true}
           sx={{ width: '100%'}}>
           <OrgContent
             key={props.node.ref}
             content={props.node.contents} />
         </List>);
}

function Item(props) {
  // console.log('ITEM', props.node.properties.tag);
  // TODO: Better bullets/separators
  // .Nested lists behave incorrectly
  const bullet = props.node.properties.bullet;
  const tag = props.node.properties.tag;

  return(<ListItem key={props.node.ref}>
           <Box component="span"
                sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
           >
             {bullet.startsWith('-') ? '•' : props.node.properties.bullet}
           </Box>
           <OrgContent
             key='item'
             content={tag} />
           
           {tag.length > 0 && <span> :: </span>}

           <OrgContent
             key='value'
             content={props.node.contents} />
         </ListItem>);
}

function QuoteBlock(props) {
  // TODO: Styling
  return(<Card>
           <CardActionArea>
             <CardContent>
               <OrgContent
                 key={props.node.ref}
                 content={props.node.contents} />
             </CardContent>
           </CardActionArea>
         </Card>);
}

function SrcBlock(props) {
  // TODO: Syntax highlighting
  // console.log('SRC', props.node);
  return(<div key={props.node.ref}>
           <pre>
             {props.node.properties.value}
           </pre>
         </div>);
}
function Verbatim(props) {
  // TODO: Styling
  return(<span key={props.node.ref}>{props.node.properties.value} </span>);
}

function OrgLink(props) {
  const node = props.node;
  const link = node.properties;
  const isImage = link['is-inline-image'];

  // console.log('LINK', props.node);

  var destination = link['raw-link'];

  if(link.type === 'id') {
    destination = '#' + link.path;
  }

  return(<React.Fragment>
           {!isImage && <Link href={destination}
                        color='inherit'>
                          {node.contents.length > 0 &&
                           <OrgContent content={node.contents} />}
                          {node.contents.length === 0 &&
                           destination}
                        </Link>}
           {isImage && <img href={destination} alt='' />}
         </React.Fragment>);
}

function OrgTable(props) {
  // NOTE: Header is detected as header->rule->line
  // .Tables without rules will be rendered without explicit header row
  var contents = [...props.node.contents];
  const hasHeader = contents.length > 2 && contents[1].properties.type === 'rule';
  var header;
  if(hasHeader) {
    header = contents[0];
    contents = contents.slice(2);
  }

  return(
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        {hasHeader &&
         <TableHead>
           <OrgContent content={[header]} />
         </TableHead>}
        <OrgContent content={contents} />
      </Table>
    </TableContainer>);
}

function OrgTableRow(props) {
  // console.log('ROW', props.node.properties.type);
  return(<TableRow>
           <OrgContent content={props.node.contents} />
         </TableRow>);
}

function OrgTableCell(props) {
  // console.log('CELL', props.node);
  return(<TableCell>
           <OrgContent content={props.node.contents} />
         </TableCell>);
}

function Italic(props) {
  return(<i><OrgContent content={props.node.contents} /></i>);
}

function Timestamp(props) {
  // TODO:
  return(<span key={props.node.ref}>{props.node.properties['raw-value']}</span>);
}

const typeHandlers = {
  'section': Section,
  'keyword': Keyword,
  'paragraph': Paragraph,
  'bold': Bold,
  'headline': Headline,
  'plain-list': PlainList,
  'item': Item,
  'quote-block': QuoteBlock,
  'src-block': SrcBlock,
  'verbatim': Verbatim,
  'link': OrgLink,
  'table': OrgTable,
  'table-row': OrgTableRow,
  'table-cell': OrgTableCell,
  'italic': Italic,
  'timestamp': Timestamp,
}

function dispatchNode(node, props) {
  const handler = typeHandlers[node.type];

  if(handler) {
    return handler({node: node});
  }
  else {
    // console.log(`Unhandled type: ${node.type}`);
    // console.log('DATA:', node);
  }
}

function OrgContent(props) {
  // Helper for handling generic content
  // .Generate fragments here to keep the hierarchy flat.
  return(
    <React.Fragment>
      {props.content && props.content.map((node, i) => {
        if(props.limit && i >= props.limit) {
          return null;
        }

        // Raw strings
        if(typeof(node) == 'string') {
          const lines = node.split('\n');
          return lines.map((line, i) => {
            return(<React.Fragment>
                     <span key={line}>{line}</span>
                     {lines.length > 1 && i < lines.length - 1 && <br/>}
                   </React.Fragment>);
          });
        }

        if(props.filter && !props.filter.includes(node.type)) {
          return null;
        }

        return dispatchNode(node);
      })}
    </React.Fragment>);
}

function KnowledgeDocument(props) {
  if(!props.storeData) {
    return(<div>loading</div>);
  }

  return(<div>
           <OrgContent
             content={props.storeData.contents}
             filter={['headline']}
           />
         </div>);
}

export default KnowledgeDocument;
