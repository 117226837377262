import logo from './logo.svg';
import './App.css';

import React from 'react';
import { Component, useState, useEffect } from 'react';

import Container from '@mui/material/Container';

import KnowledgeDocument from './KnowledgeDocument';
import MainBar from './MainBar';

import { GoogleLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [storeData, setStoreData] = useState();
  const [auth, setAuth] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    if(!auth) {
      return;
    }

    async function fetchData() {
      const listReq = await fetch(`https://www.googleapis.com/drive/v3/files?q=name='test.json'`, {
        headers: {
          Authorization: 'Bearer ' + auth,
          Accept: 'application/json',
        },
      });

      const listData = await listReq.json();
      const dbFileId = listData.files[0].id;

      const req = await fetch(`https://www.googleapis.com/drive/v3/files/${dbFileId}?alt=media`, {
        headers: {
          Authorization: 'Bearer ' + auth,
          Accept: 'application/json',
        },
        mode: 'cors',
      });

      const data = await req.json();
      console.log(data);
      setStoreData(data);
    }

    fetchData();
  }, [auth]);

  const responseMessage = (response) => {
    setAuth(response.access_token);
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  const login = useGoogleLogin({
    flow: 'implicit',
    scope: "https://www.googleapis.com/auth/drive.readonly",
    onSuccess: responseMessage,
    onError: errorMessage
  });

  const logout = () => {
    googleLogout();
    setStoreData(null);
    setAuth(null);
  };

  return (<div>
            <div>
              {!auth ?
               <button onClick={() => login()}>Sign in with Google 🚀 </button>
               : <button onClick={() => logout()}>Logout</button>
              }
            </div>

            {storeData && <ThemeProvider theme={darkTheme}>
                            <CssBaseline />
                            <Container
                              maxWidth="sm">
                              <KnowledgeDocument storeData={storeData} />
                              <MainBar />
                            </Container>
                          </ThemeProvider>}
            
          </div>
         );}

export default App;
